import { CallbackID, Geolocation, Position } from "@capacitor/geolocation";
import { isPlatform } from "@ionic/react";
import { isAndroidPlatform, isCapacitorPlatform } from "@src/appV2/lib";
import {
  GeoLocation,
  LegacyGeoLocationCoordinates,
  convertToLegacyGeoLocationCoordinates,
  getDeviceGeoLocation,
} from "@src/appV2/Location";

import { LocationAlertType, enableGeoLocation, getUnknownErrorString } from "./enable";

export const getOrWatchCurrentLocation = async (
  specificTimeoutForAndroid?: number
): Promise<{
  location?: LegacyGeoLocationCoordinates;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  const { success, error, errorDetails } = await enableGeoLocation();
  if (!success) {
    return {
      error,
      errorDetails,
    };
  }
  let positionResponse = await getCurrentPosition(specificTimeoutForAndroid);
  let { geoLocation, error: positionError, errorDetails: positionErrorDetails } = positionResponse;
  if (isPlatform("capacitor") && positionError === LocationAlertType.COORDS_NOT_AVAILABLE) {
    positionResponse = await watchPosition();
    ({ geoLocation } = positionResponse);
    positionError = positionResponse.error;
    positionErrorDetails = positionResponse.errorDetails;
  }

  if (positionError) {
    return {
      error: positionError,
      errorDetails: positionErrorDetails,
    };
  }
  return {
    /**
     * FIXME: Convert consumers to use geoLocation
     */
    location: geoLocation ? convertToLegacyGeoLocationCoordinates(geoLocation) : undefined,
  };
};

// TODO inline this inside getOrWatchCurrentLocation
// this function isn't used anywhere else
const getCurrentPosition = async (
  specificTimeoutForAndroid = 60_000
): Promise<{
  geoLocation?: GeoLocation;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  try {
    const { geoLocation } = await getDeviceGeoLocation({
      // For Android, 'specificTimeoutForAndroid' is being sent as 10000 specifically for clock in/out actions
      // because timeout=60000 takes more time to fetch location and thus delays the clock in/out actions
      // whereas timeout=10000 makes it faster with good location accuracy
      timeoutInMilliseconds: isAndroidPlatform() ? specificTimeoutForAndroid : 60_000,
      // For Mobile, 60 sec gives low error
      // For Web and testing purposes, we prefer to have exact coordinates (mostly provided by Chrome dev tools` fake location)
      maximumAgeInMilliseconds: isCapacitorPlatform() ? 60_000 : 0,
    });
    return {
      geoLocation,
    };
  } catch (error) {
    return {
      error: LocationAlertType.COORDS_NOT_AVAILABLE,
      errorDetails: getUnknownErrorString(error),
    };
  }
};

const watchPosition = async (): Promise<{
  geoLocation?: GeoLocation;
  error?: LocationAlertType;
  errorDetails?: string;
}> => {
  return new Promise((resolve, reject) => {
    try {
      let watchId: CallbackID;
      const watchPositionCallback = async (position: Position | null, error: unknown) => {
        if (error) {
          resolve({
            error: LocationAlertType.COORDS_NOT_AVAILABLE,
            errorDetails: getUnknownErrorString(error),
          });
        }

        if (position) {
          await Geolocation.clearWatch({ id: watchId });
          resolve({
            geoLocation: position.coords,
          });
        }
      };

      Geolocation.watchPosition({}, watchPositionCallback).then((value) => (watchId = value));
    } catch (error) {
      resolve({
        error: LocationAlertType.COORDS_NOT_AVAILABLE,
        errorDetails: getUnknownErrorString(error),
      });
    }
  });
};
